import {
  Box,
  Button,
  Checkbox,
  Dialog,
  IconButton,
  Step,
  StepLabel,
  Stepper,
  styled,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { BasicForm, BasicFormSubmit, StyledTextField } from "./Form";
import "./Modals.css";
import { Check, Close } from "@mui/icons-material";

const StyledModalBox = styled(Box)({
  width: "30rem",
  height: "40rem",
  display: "flex",
  margin: "1rem",
});

const Stylog = styled(Dialog)({
  "& .MuiDialog-paper": {
    backgroundColor: "darkslategrey",
  },
});

const StyledStepLabel = styled(StepLabel)({
  "& .MuiStepLabel-label": {
    color: "white",
  },
});

const StyledCheckBox = styled(Checkbox)({
  color: "white",
  "&.Mui-checked": {
    color: "white",
  },
});

const StyledStep = styled(Step)({
  color: "white",
  "& .Mui-completed": { color: "white" },
  "& .Mui-active": { color: "wheat" },
  "& .MuiStepIcon-text": { fill: "dimgrey" },
});

const Modals = () => {
  const [formDialogOpen, setFormDialogOpen] = useState(false);
  const [stepperDialogOpen, setSteppeDialogOpen] = useState(false);
  const ModalWithForm = () => {
    const onSubmit = ({ name, main, address, phone }: BasicFormSubmit) => {
      window.alert(
        `Name: ${name}, Main: ${main}, Address: ${address}, Phone: ${phone}`
      );
    };
    return (
      <Stylog
        className="dialog-container"
        open={formDialogOpen}
        onClose={() => setFormDialogOpen(false)}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          pl="1rem"
          pr="1rem"
        >
          <Typography color="white">Basic Dialog with Form</Typography>
          <IconButton
            sx={{ alignSelf: "flex-end" }}
            onClick={() => setFormDialogOpen(false)}
          >
            <Close />
          </IconButton>
        </Box>
        <StyledModalBox>{BasicForm(onSubmit)}</StyledModalBox>
      </Stylog>
    );
  };

  const ModalWithStepper = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set<number>());
    const [isOptionalChecked, setIsOptionalChecked] = useState(false);
    const [textFieldValue, setTextFieldValue] = useState("");

    const onSubmit = () => {
      window.alert(
        `Box Checked: ${isOptionalChecked} \n Text Input: ${textFieldValue}`
      );
      handleReset();
    };

    const isStepOptional = (step: number) => {
      return step === 1;
    };

    const isStepSkipped = (step: number) => {
      return skipped.has(step);
    };

    const handleNext = () => {
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }

      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    };

    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
      if (!isStepOptional(activeStep)) {
        // You probably want to guard against something like this,
        // it should never occur unless someone's actively trying to break something.
        throw new Error("You can't skip a step that isn't optional.");
      }

      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped((prevSkipped) => {
        const newSkipped = new Set(prevSkipped.values());
        newSkipped.add(activeStep);
        return newSkipped;
      });
    };

    const stepComponent = (step: number) => {
      switch (step) {
        case 0:
          return (
            <Typography sx={{ mt: 2, mb: 1, color: "white" }}>
              Use this stepper to test your BEAST skills at dialogs and next
              steps. The next step is optional, but it will have a checkbox that
              you can select. Its value will be put in the alert once you click
              submit
            </Typography>
          );
        case 1:
          return (
            <Box display="flex" flexDirection="column" justifySelf="center">
              <Typography color="white">Will you check this box?</Typography>
              <StyledCheckBox
                data-testId="stepper-checkbox-button"
                checked={isOptionalChecked}
                onClick={() => setIsOptionalChecked(!isOptionalChecked)}
              />
            </Box>
          );

        case 2:
          return (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              width={"100%"}
            >
              <Typography color="white">This field is required!!!</Typography>
              <StyledTextField
                onChange={(e) => setTextFieldValue(e.target.value)}
                required
                fullWidth
                data-testId="stepper-text-field"
                value={textFieldValue}
              />
            </Box>
          );

        default:
          break;
      }
    };

    const handleReset = () => {
      setSteppeDialogOpen(false);
      setIsOptionalChecked(false);
      setTextFieldValue("");
      setActiveStep(0);
    };
    const steps = ["Step 1 Test", "Step 2 Test", "Step 3 Test"];
    return (
      <Stylog open={stepperDialogOpen} data-testId="stepper-dialog">
        <StyledModalBox
          sx={{
            flexDirection: "column",
            height: "15rem",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps: { completed?: boolean } = {};
                const labelProps: {
                  optional?: React.ReactNode;
                } = {};
                if (isStepOptional(index)) {
                  labelProps.optional = (
                    <Typography color="dimgrey" variant="caption">
                      Optional
                    </Typography>
                  );
                }
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <StyledStep
                    sx={{
                      color: "white",
                      "& .Mui-completed": { color: "white" },
                      "& .Mui-active": { color: "wheat" },
                      "& .MuiStepIcon-text": { fill: "dimgrey" },
                    }}
                    key={label}
                    {...stepProps}
                  >
                    <StyledStepLabel
                      sx={{
                        color: "white",
                        "& .Mui-completed": { color: "white" },
                        "& .Mui-active": { color: "wheat" },
                        "& .MuiStepIcon-text": { fill: "dimgrey" },
                      }}
                      {...labelProps}
                    >
                      {label}
                    </StyledStepLabel>
                  </StyledStep>
                );
              })}
            </Stepper>
          </Box>
          <Box>{stepComponent(activeStep)}</Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              pt: 2,
            }}
          >
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1, color: "white" }}
              data-testId="stepper-back-button"
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            {isStepOptional(activeStep) && (
              <Button
                color="inherit"
                onClick={handleSkip}
                sx={{ mr: 1, color: "white" }}
                data-testId="stepper-skip-button"
              >
                Skip
              </Button>
            )}
            <Button
              onClick={() => {
                activeStep === steps.length - 1 && textFieldValue.length !== 0
                  ? onSubmit()
                  : handleNext();
              }}
              disabled={
                activeStep === steps.length - 1 && textFieldValue.length === 0
              }
              data-testId="next-or-submit-button"
              sx={{ color: "dimgrey", backgroundColor: "wheat" }}
              variant="contained"
            >
              {activeStep === steps.length - 1 ? "Submit" : "Next"}
            </Button>
          </Box>
        </StyledModalBox>
      </Stylog>
    );
  };

  return (
    <div className="dialog-main-container">
      <Button
        onClick={() => setFormDialogOpen(true)}
        sx={{ mt: "1rem", backgroundColor: "dimgrey" }}
        disabled={formDialogOpen}
        variant="contained"
        data-testId="form-modal-button"
      >
        Click to Open Form Dialog
      </Button>
      <Button
        onClick={() => setSteppeDialogOpen(true)}
        sx={{ mt: "1rem", backgroundColor: "dimgrey" }}
        disabled={formDialogOpen}
        variant="contained"
        data-testId="stepper-modal-button"
      >
        Click to Open Stepper Dialog
      </Button>
      {ModalWithForm()}
      {ModalWithStepper()}
    </div>
  );
};
export default Modals;
