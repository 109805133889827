import { Box, Button, styled, TextField, Typography } from '@mui/material';

import './Form.css';
import { ChangeEvent, useState } from 'react';

export const StyledTextField = styled(TextField)({
  backgroundColor: 'dimgrey',
  borderRadius: '.25rem',
  color: 'white',

  '& .MuiInputBase-input': {
    color: 'white',
  },

  '& input:-webkit-autofill': {
    transition: 'background-color 600000s 0s, color 600000s 0s',
  },

  '& .MuiFormLabel-root': {
    color: 'Bisque',
    '&.Mui-focused': {
      color: 'white',
    },
  },

  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'Bisque',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
});

export interface BasicFormSubmit {
  name: string;
  main: string;
  address?: string;
  phone?: string;
}

export const BasicForm = (
  onSubmit: ({ name, main, address, phone }: BasicFormSubmit) => void
) => {
  const [address, setAddress] = useState('');
  const [name, setName] = useState('');
  const [main, setMain] = useState('');
  const [phone, setPhone] = useState('');

  return (
    <Box
      width={'35rem'}
      gap={'.75rem'}
      display={'flex'}
      flexDirection={'column'}
    >
      <StyledTextField
        onChange={(e) => setName(e.target.value)}
        value={name}
        fullWidth
        required
        label="Name"
        data-testId="name-field"
      />
      <StyledTextField
        onChange={(e) => setMain(e.target.value)}
        value={main}
        required
        fullWidth
        label="Main in Marvel Rivals"
        data-testId="main-field"
      />
      <StyledTextField
        onChange={(e) => setAddress(e.target.value)}
        value={address}
        fullWidth
        label="Address"
        data-testId="address-field"
      />
      <StyledTextField
        onChange={(e) => setPhone(e.target.value)}
        value={phone}
        fullWidth
        label="Phone Number"
        data-testId="phone-field"
        error={
          !!phone.length &&
          !/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(
            phone
          )
        }
      />
      <Typography color="white">* indicated required field</Typography>
      <Button
        sx={{ alignSelf: 'flex-start', backgroundColor: 'dimgrey' }}
        variant="contained"
        disabled={!(name.length && main.length)}
        onClick={() => onSubmit({ name, main, address, phone })}
        data-testId="submit-form-button"
      >
        Submit
      </Button>
    </Box>
  );
};

const Form = () => {
  const onSubmit = ({ name, main, address, phone }: BasicFormSubmit) => {
    window.alert(
      `Name: ${name}, Main: ${main}, Address: ${address}, Phone: ${phone}`
    );
  };
  return (
    <Box className="main-container">
      <Typography className="instruction-text">
        Use this page to test your form input and validation tests
      </Typography>
      {BasicForm(onSubmit)}
    </Box>
  );
};
export default Form;
