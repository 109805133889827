import { Box, Button, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import MemoryGame from "react-card-memory-game";
import Confetti from "react-confetti";

const Menus = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const [showConfetti, setShowConfetti] = useState(false);
  const [showGame, setShowGame] = useState(false);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItems = [
    {
      label: showGame ? "Close Game" : "Want to play a game?",
      onClickAction: () => {
        setShowGame(showGame ? false : true);
        handleClose();
      },
      testId: "game-button",
    },
    {
      label: showConfetti ? "Return the prize" : "Click for a prize",
      onClickAction: () => {
        setShowConfetti(showConfetti ? false : true);
        handleClose();
      },
      testId: "gift-button",
    },
  ];

  return (
    <Box className="main-container">
      <Button
        onClick={handleClick}
        sx={{ mt: "1rem", backgroundColor: "dimgrey" }}
        variant="contained"
        data-testId="menu-open-button"
      >
        Click to Open the Menu
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        data-testId="menu-dropdown"
      >
        {menuItems.map((item, index) => {
          return (
            <MenuItem onClick={item.onClickAction} data-testId={item.testId}>
              {item.label}
            </MenuItem>
          );
        })}
      </Menu>
      {showConfetti && <Confetti />}
      {showGame && (
        <MemoryGame
          gridNumber={4}
          foundCardsColor="dimgrey"
          holeCardsColor="wheat"
          gameFinished={() => setShowConfetti(true)}
        />
      )}
    </Box>
  );
};
export default Menus;
