import * as React from "react";
import {
  Drawer,
  Toolbar,
  List,
  styled,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  ChevronLeft,
  ChevronRight,
  EditNote,
  PictureInPicture,
  Home,
  Menu,
} from "@mui/icons-material";

import homeMe from "../homeMe.png";
import { useState } from "react";
import { AppBar } from "./AppBar";
import { useNavigate } from "react-router";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const Navigation = () => {
  const [open, setOpen] = useState(false);
  let navigate = useNavigate();

  const listOfTasks = [
    { name: "Home", icon: <Home />, route: "/" },
    { name: "Form Input", icon: <EditNote />, route: "/form" },
    { name: "Modals", icon: <PictureInPicture />, route: "/modals" },
    { name: "Menus", icon: <Menu />, route: "/menus" },
  ];

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const drawerWidth = 240;
  return (
    <>
      <AppBar position="fixed" open={open}>
        <Toolbar
          sx={{
            backgroundColor: "DarkSlateGray",
            boxShadow: "0px 4px 5px #282c34",
          }}
          data-testId="main-toolbar"
        >
          <IconButton
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={[open && { display: "none" }]}
            data-testId="sidebar-open-button"
          >
            <img src={homeMe} className="menu-button " alt="logo" />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: "DarkSlateGray",
            boxShadow: "0px 4px 5px #282c34",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
        data-testId="main-nav-drawer"
      >
        <DrawerHeader>
          <IconButton
            data-testId="main-nav-draw-chevron-button"
            onClick={handleDrawerClose}
            sx={{ color: "white" }}
          >
            {open ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {listOfTasks.map((task, index) => (
            <ListItem
              key={task.name + index}
              disablePadding
              sx={{ color: "white" }}
            >
              <ListItemButton
                onClick={() => {
                  navigate(task.route);
                  setOpen(false);
                }}
                data-testId={`$${index}-nav-sidebar-option`}
              >
                <ListItemIcon sx={{ color: "white" }}>{task.icon}</ListItemIcon>
                <ListItemText primary={task.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </>
  );
};

export default Navigation;
